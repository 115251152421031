<template>
  <div class="wrap">
    <div class="overlay" v-if='show' @click="change"></div>
    <div style='position: relative'>
      <div class="f_flex f_a_c f_j_c" @click='change'>
        <slot>{{title}}</slot>
        <slot name='icon'>
          <i :class="{iconAct: checked}" class="iconfont icon-triangle"></i>
        </slot>
      </div>
      <div v-if='desc' class="desc">{{desc}}</div>
      <div class="popupwrap" v-if='checked'>
        <div class="popupDialog transform_r_c" :style="dialogStyle">
          <div class="triangle transform_r_c" :style="triangleStyle"></div>
          <div class="content">
            <div
              @click="pickItemFn(item, index)"
              class="f_flex f_a_c f_j_sb pickItem"
              :class="{pickBtmBd: index < list.length-1}"
              v-for='(item, index) in list'
              :key='index'>
              <div style="text-align: left" class="text_line1">{{item[text]}}</div>
              <div>
                <i v-if='index === act' class="iconfont icon-check1"></i>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data: ()=> ({
    show: false,
    act: 0
  }),
  props: {
    checked: Boolean,
    title: String,
    desc: String,
    text: {
      type: String,
      default: 'companyName'
    },
    value: {
      type: String,
      default: 'groupId'
    },
    list: {
      type: Array,
      default: ()=> ([])
    },
    id: {
      type: Number,
      default: 0
    },
    dialogStyle: {
      type: Object,
      default: ()=> ({})
    },
    triangleStyle: {
      type: Object,
      default: ()=> ({})
    }
  },
  model: {
    prop: 'checked',
    event: 'change'
  },
  watch: {
    checked(n) {
      this.show = n
    },
    id(n) {
      const list = this.list
      // console.log('===', n);
      list.forEach((item, index) => {
        if (item[this.value] === Number(n)) {
          this.act = index
        }
      });
    }
  },
  methods: {
    change() {
      this.show = !this.show
      this.$emit('change', this.show)
    },
    pickItemFn(item, index) {
      let same = false
      if (this.act == index) {
        same = true
      }
      this.act = index
      this.$emit('pick', {item, index, same})
    }
  },
  mounted() {
    const list = this.list
    list.forEach((item, index) => {
      if (item[this.value] === Number(this.id)) {
        this.act = index
      }
    });
  }
}
</script>

<style lang="less" scoped>
.wrap {
  position: relative;
  // z-index: 10;
  .title {
    position: relative;
    font-size: 16px;
    color: #333;
    line-height: 16px;
  }
  .desc {
    color: #999;
    line-height: 14px;
    font-size: 12px;
    text-align: center;
  }
  .icon-triangle {
    font-size: 20px;
    position: relative;
    color: #666;
    transition: all 300ms linear;
  }
  .iconAct {
    transform: rotate(180deg);
  }
  .popupwrap {
    position: relative;
    z-index: 9;
  }
  .popupDialog {
    position: absolute;
    width: 250px;
    
    color: #fff;
    background: #363636;
    top: 8px;
    text-align: center;
    border-radius: 6px;
    box-sizing: border-box;
    .triangle {
      position: absolute;
      border: 8px solid transparent;
      border-bottom-color: #363636;
      top: -16px;
    }
    .pickItem {
      height: 52px;
      font-size: 13px;
      width: 100%;
    }
    .pickBtmBd {
      border-bottom: 1px solid #464646;
    }
  }
  .content {
    max-height: 300px;
    overflow: auto;
    padding: 10px 20px;
  }
}
.icon-check1 {
  color: #fff
}
.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 2;
  background: rgba(0,0,0,0);
}
</style>
