<template>
  <div class="infoItem f_flex f_a_c f_j_sb underline">
    <div class="f_flex f_a_c">
      <slot name='img'></slot>
      <p class="i_txt">{{title}}</p>
    </div>
    <div class="f_flex f_a_c">
      <div class="news" v-if='news'></div>
      <i class="iconfont icon-ARROW icon_arrow"></i>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: String,
    src: String,
    news: Boolean
  }
}
</script>

<style lang="less" scoped>
.infoItem {
  position: relative;
  height: 52px;
  // border-bottom: 1px solid #333;
  &:active {
    background: rgba(214, 214, 214, 0.1);
  }
  
  .i_txt {
    font-size: 15px;
    color: #2D2D2D;
  }
}
.news {
  width: 7px;
  height: 7px;
  border-radius: 7px;
  background: #EA574C;
  margin-right: 11px;
}
.icon_arrow {
  font-size: 16px;
  color: #C3C3C3;
}
</style>