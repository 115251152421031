<template>
  <div class="bottomMenu f_flex f_a_c f_j_sa">
    <div class="menuItem f_flex f_col f_j_c f_a_c" @click='gopage(item)' v-for='(item, index) in menu' :key='index'>
      <div style="width: 29px; height: 29px">
        <img class="icon_menu" v-if='$route.name===item.router' :src="item.selectIcon" alt="">
        <img class="icon_menu" v-else :src="item.unselect" alt="">
      </div>
      <p class="menuTitle" :class='{menuTitle_act: $route.name===item.router}'>{{item.title}}</p>
    </div>
  </div>
</template>

<script>
import icon_home_e from '@/assets/menu_home_e.png'
import icon_home from '@/assets/menu_home.png'
import icon_group_e from '@/assets/menu_group_e.png'
import icon_group from '@/assets/menu_group.png'
import icon_set_e from '@/assets/menu_set_e.png'
import icon_set from '@/assets/menu_set.png'
import {routerDirection} from '@/common/js/router'

export default {
  data: ()=> ({
    act: 0,
    menu: [
      {
        title: '首页',
        router: 'home',
        key: 'indexIconList',
        selectIcon: icon_home,
        unselect: icon_home_e
      },
      {
        title: '群',
        router: 'group',
        key: 'groupIconList',
        selectIcon: icon_group,
        unselect: icon_group_e
      },
      {
        title: '设置',
        router: 'setting',
        key: 'settingIconList',
        selectIcon: icon_set,
        unselect: icon_set_e
      }
    ]
  }),
  methods: {
    gopage(item) {
      if (item.key == 'discover') {
        window.open(item.router,'_blank')
        return
      }

      routerDirection('fade')
      
      setTimeout(()=> {
        const h = window.sessionStorage.getItem('routerHistory')
        if (h) {
          const history = JSON.parse(h)
          history.pop()
          history.push(this.$route.name)
          window.sessionStorage.setItem('routerHistory', JSON.stringify(history))
        }
      }, 100)
      this.$router.replace(`/${item.router}`)
    }
  },
  mounted() {
    const menuList = this.$global.getItem('appConfig').menuList
    if (menuList) {
      console.log(this.$route.name);

      if (menuList.discoverJumpUrl) {
        this.menu.splice(2, 0, {
          title: '发现',
          router: menuList.discoverJumpUrl,
          key: 'discover',
          selectIcon: menuList.discoverIconSelectUrl,
          unselect: menuList.discoverIconNormalUrl
        })
      }

      this.menu.forEach(item => {
        const key = item.key
        // const selected = menuIcon[key].find(ii => ii.selectedType == 1)
        // const unselect = menuIcon[key].find(ii => ii.selectedType == 2)
        // item.selectIcon = selected.iconUrl
        // item.unselect = unselect.iconUrl
        switch (key) {
          case 'indexIconList':
            item.selectIcon = (menuList.indexIconSelectUrl && menuList.indexIconSelectUrl.length > 0) ? menuList.indexIconSelectUrl : item.selectIcon
            item.unselect = (menuList.indexIconNormalUrl && menuList.indexIconNormalUrl.length > 0) ? menuList.indexIconNormalUrl : item.unselect
            break
          case 'groupIconList':
            item.selectIcon = (menuList.groupIconSelectUrl && menuList.groupIconSelectUrl.length > 0) ? menuList.groupIconSelectUrl : item.selectIcon
            item.unselect = (menuList.groupIconNormalUrl && menuList.groupIconNormalUrl.length > 0) ? menuList.groupIconNormalUrl : item.unselect
            break
          case 'settingIconList':
            item.selectIcon = (menuList.settingIconSelectUrl && menuList.settingIconSelectUrl.length > 0) ? menuList.settingIconSelectUrl : item.selectIcon 
            item.unselect = (menuList.settingIconNormalUrl && menuList.settingIconNormalUrl.length > 0) ? menuList.settingIconNormalUrl : item.unselect 
            break
          default:
            console.log('key not match');

        }
      })
    }
  }
}
</script>

<style lang="less" scoped>
.bottomMenu {
  position: absolute;
  width: 100%;
  height: 57px;
  background: #fff;
  border-top: 1px solid #eee;
  bottom: 0;
  // z-index: 2;
  .icon_menu {
    width: 29px;
    height: 29px;
    line-height: 29px;
  }
  .menuTitle {
    font-size: 11px;
    color: #9B9B9B;
    line-height: 16px;
    margin-bottom: 12px;
  }
  .menuTitle_act {
    color: #000;
  }
}
</style>