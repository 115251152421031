export default {
  computed: {
    _getCompanyInfo() {
      let c = undefined;
      const companyInfo = this.$global.getItem('companyInfo', true)
      const companyInfoList = this.$global.getItem('companyInfoList', true)
      const companyId = companyInfo ? companyInfo.companyId : null
      if (companyId) {
        const i = companyInfoList.find(item => item.companyId == companyId)
        if (i) {
          c = i
        } else {
          c = companyInfoList[0]
        }
      } else {
        c = companyInfoList[0]
      }
      // this.$global.setItem('companyInfo', c, true)
      return c
    }
  }
}